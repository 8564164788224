<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item v-if="canSave">
            <a-button icon="plus" type="primary" @click="showEditModal()">新增证书</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>证书名称</span>
              </template>
              <a-input v-model.trim="searchData.certificate_name" placeholder="证书名称" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item v-if="canIndex">
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="middle" :pagination="false" :bordered='false' rowKey="cert_id"
        :columns="columns" :dataSource="list">
          <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>
          <template slot="level" slot-scope="text,record">
            <span>{{ certificateLevel(text) }}</span>
          </template>
          <template slot="status" slot-scope="text,record">
              <a-switch checkedChildren="有效" unCheckedChildren="无效" :checked="text=== 1" @change="changeStatus(record)"/>
          </template>
          <template slot="action" slot-scope="text,record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">操作<a-icon type="down" /></a>
              <a-menu slot="overlay">
                <a-menu-item v-if="canUpdate" key="1" @click="showEditModal(record)">
                  编辑
                </a-menu-item>
                <a-menu-item key="2" @click="showSendModal(record)">
                  发放
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
    <editModal v-if="editVisible" :modalData="modalData"/>
    <sendModal v-if="sendVisible" :item="modalData"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '证书名称', dataIndex: 'certificate_name', key: 'name' },
  { title: '证书级别', dataIndex: 'certificate_level', key: '1', scopedSlots: { customRender: 'level' } },
  { title: '课程科目', dataIndex: 'course_cate_name', key: '2' },
  { title: '添加时间', dataIndex: 'created_at', key: '3' },
  { title: '状态', dataIndex: 'certificate_status', key: '4', scopedSlots: { customRender: 'status' }},
  { title: '操作', key: 'operation', align: 'right', width: 136, scopedSlots: { customRender: 'action' }},
]
		
import C_ITEMS from '@/utils/items'
import editModal from './editModal'
import sendModal from './sendModal'
import authority from '@/common/mixins/authority'
import tableMixins from '@/common/mixins/table'
export default {
  name: 'gjjl',
  provide() {
    return {
      parent: this
    }
  },

  components: {
    editModal,
    sendModal,
  },

  data() {
    return {
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,

      certificateLevels: C_ITEMS.certificateLevels,
      studios: [],
      consultants: [],
      channels: [],

      searchData: {},

      visible: false,
      editVisible: false,
      sendVisible: false,
      authType:['education','certificate'],
    }
  },
  mixins: [ authority, tableMixins ],
  created() {
    this.getStudio()
    this.getConsultant()
    this.getSourceChannel()
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    certificateLevel(value) {
      let label = ''
      for (let item of this.certificateLevels) {
        if (item.value == value) {
          label = item.label
          break
        }
      }
      return label
    },
    closeSearch() {
      this.visible = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },

    async getConsultant() {
      let res = await this.$store.dispatch('searchConsultantAction', {})
      this.consultants = res.data
    },

    async getSourceChannel(parent_id) {
      let searchParams = {}
      if (parent_id) {
        searchParams.parent_id = parent_id
      }
      let res = await this.$store.dispatch('searchSourceChannelAction', { data: searchParams })
      if (parent_id) {
        this.channels = res.data
      } else {
        this.parentChannels = res.data
      }
    },
    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'created_date') {
            obj['start_date'] = searchData[k][0]
            obj['end_date'] = searchData[k][1]
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('courseCertificateAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    
    showEditModal(item) {
      this.modalData = item
      this.editVisible = true
    },
    hideEditModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.editVisible = false
    },
    
    showSendModal(item) {
      this.modalData = item
      this.sendVisible = true
    },
    hideSendModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.sendVisible = false
    },
    
    deleteItem(item) {
      let that = this
      this.$confirm({
        title: `您确定要取消发放"${item.certificate_name}"吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('courseCertificateDeleteAction', { data: { cert_id: item.cert_id } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
    recoveryItem(item) {
      let that = this
      this.$confirm({
        title: `您确定要发放"${item.certificate_name}"吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('courseCertificateRecoveryAction', { data: { cert_id: item.cert_id } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
    changeStatus(record){
        if (record.certificate_status === 1) {
            this.deleteItem(record)
        } else {
            this.recoveryItem(record)
        }
    },
  }
}
</script>
