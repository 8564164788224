<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>学员手机号</span>
              </template>
              <a-input allowClear v-model.trim="searchData.user_cellphone" placeholder="学员手机号" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>发放的证书</span>
              </template>
              <a-select allowClear v-model="searchData.cert_id" placeholder="请选择发放的证书" style="width: 200px">
                <a-select-option v-for="(d, index) of certificates" :key="index" :value="d['cert_id']">{{ d['certificate_name'] }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="middle" :pagination="false" :bordered='false' rowKey="id"
        :columns="columns" :dataSource="list">
        <template slot="index" slot-scope="text, record , index">
          <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>
        <template slot="studentName" slot-scope="text, record">
          <LUser :data="record"/>
        </template>
        
        <template slot="images" slot-scope="text, record">
          <LImg :src="text" :preview="record.id"  style="width: 150px; height: 72px; margin: 0 5px 5px 0"/>
        </template>
        <template slot="action" slot-scope="text,record">
          <a href="javascript:;" @click="toWithdraw(record)">撤回</a>
        </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index',fixed: 'left', },
  { title: '学员名称', width: '200px', dataIndex: 'student_name', key: 'student_name', fixed: 'left', scopedSlots: { customRender: 'studentName' } },
  { title: '证书名称', dataIndex: 'certificate_name', key: '1' },
  { title: '证书编号', dataIndex: 'certificate_no', key: 'certificate_no' },
  { title: '证书级别', dataIndex: 'certificate_level', key: '4' },
  { title: '证书图片', dataIndex: 'certificate_image', key: '2', scopedSlots: { customRender: 'images' } },
  { title: '经办人', dataIndex: 'created_by', key: 'created_by' },
  { title: '备注', dataIndex: 'certificate_remark', width:'200px', key: 'certificate_remark' ,ellipsis: true},
  { title: '发放时间', dataIndex: 'created_at', key: '5' },
  { title: '操作', key: 'operation', align: 'right', width: 136, scopedSlots: { customRender: 'action' }},
]
					

import C_ITEMS from '@/utils/items'
import tableMixins from '@/common/mixins/table'
export default {
  name: 'gjjl',

  data() {
    return {
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,

      certificates: [],

      searchData: {},

      visible: false
    }
  },
  mixins:[ tableMixins ],
  created() {
    this.getCertificate()
  },

  methods: {
    closeSearch() {
      this.visible = false
    },
    async getCertificate() {
      let res = await this.$store.dispatch('courseCertificateAction', {})
      this.certificates = res.items
    },
    async getList() {
      this.loading=true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'created_date') {
            obj['start_date'] = searchData[k][0]
            obj['end_date'] = searchData[k][1]
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('courseCertificateSendListAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading=false
    },
    toWithdraw(item){
      let that = this
      this.$confirm({
        title: `您确定撤回该证书？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('courseCertificateCancalCertificateAction', { data: { id: [item.id] } })
            if (res) {
              that.$message.success('操作成功~')
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    }
  }
}
</script>
