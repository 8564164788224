<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="700px"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">
      <a-form-item v-for="(item, index) of formData" :key="index" 
        :label="item.label" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
        <div v-decorator="[item.name, { rules: item.rules }]" v-if="item.type === 'upload'" style="display: inline-block; position: relative;" @click="showImagesModal(item.name)">
          <div v-if="imageUrl">
            <LImg :src="imageUrl" style="width: 100px; height: 100px;"/>
            <div class="close" @click="closeImg($event)">&times;</div>
          </div>
          <a-button v-else type="dashed" icon="file-image">选择图片</a-button>
        </div>
        <a-date-picker v-if="item.type === 'date'" style="width: 100%"  v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD"/>
        <a-select v-else-if="item.type === 'select'" placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]"
          showSearch
          :defaultActiveFirstOption="false"
          :showArrow="false"
          :filterOption="false"
          @search="handleSearch"
          @change="handleChange"
          :notFoundContent="null">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">
            <div>
                <LImg style="width:24px;height:24px;border-radius:50%;margin-right:8px" :src="d['student_avatar']"/>
                <span>{{ d[item.items.label] }}</span>
            </div>
          </a-select-option>
        </a-select>
        <a-textarea v-else-if="item.type === 'textarea'" :rows="4"
           v-decorator="[item.name, { rules: item.rules }]"/>
      </a-form-item>
    </a-form>
    <ImageModal v-if="imagesVisible"/>
  </a-modal>
</template>

<script>
const formData = [
  {
    name: 'student_id',
    label: '学员名称',
    type: 'select',
    rules: [{ required: true, message: '请选择学员!' }],
    items: {
      data: 'students',
      label: 'student_name',
      value: 'student_id'
    }
  },
  {
    name: 'certificate_no',
    label: '证书编号',
    type: 'input',
    rules: [{ required: true, message: '请输入证书编号!' }],
  },
  {
    name: 'certificate_image',
    label: '证书图片',
    type: 'upload',
    rules: [{ required: true, message: '请选择证书图片!' }]
  },
  {
    name: 'certificate_remark',
    label: '证书备注',
    type: 'textarea',
    rules: []
  },
]
import C_ITEMS from '@/utils/items'
import url from '@/utils/URL'
import createFilePath from '@/utils/tools'
import ImageModal from '@/views/modal/images'
export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object
  },
  provide() {
    return {
      parent: this
    }
  },
  components: {
    ImageModal,
  },

  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      ModalTitle: '证书发放',
      visible: false,
      confirmLoading: false,
      formData,
      form: this.$form.createForm(this),
      seleteItems: {
        students: []
      },
      imageRoot: url.imageRoot,
      imageUrl: '',
      imagesVisible: false,
      imageKey: '',
    }
  },

  async created() {
    this.visible = true
    this.handleSearch()
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    handleSearch(value) {
      this.getStudents(value)
    },
    async getStudents(value){
      let params = {
        "q": value,
      }
      let res = await this.$store.dispatch('searchStudentsAction', { params })
      this.seleteItems.students = res.data
    },
    async handleOk() {
      this.confirmLoading=true;
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if (params) {
          params.certificate_id = item.cert_id
          params.certificate_image = this.imageUrl
          let res = await this.$store.dispatch('courseCertificateSendCertificateAction', { data: params })
          if(res) {
            this.$message.success('发放成功!')
            form.resetFields()
            this.parent.hideSendModal(1)
          }
        }
      } catch {
      }
			this.confirmLoading=false;
    },
    handleCancel() {
      this.parent.hideSendModal(0)
    },

    async beforeUpload(file) {
      if (!file) { return false }
      let res = await this.$store.dispatch('massTokenAction', {})
      if (res) {
        this.uploadParams.token = res.data.uptoken
        this.uploadParams.key = createFilePath(file.name)
        this.fileList = []
        return true
      }
      return false
    },
    changeFile(file) {
      if (!this.fileList[0]) {
        this.fileList[0] = {
          uid: '1',
          size: file.file.size, 
          type: file.file.type, 
          name: this.uploadParams.key,
          status: 'done',
        }
      }
      this.fileList[0].name = this.uploadParams.key
    },
    
    closeImg(e) {
      e.stopPropagation()
      this.imageUrl = ''
      this.form.setFieldsValue({certificate_image:null})
    },
    showImagesModal(imageKey) {
      this.imageKey = imageKey
      this.imagesVisible = true
    },
    hideImagesModal(image) {
      if (image) {
        console.log(image)
        this.imageUrl = image.path
        this.imagePath = image.path
        this.form.setFieldsValue({certificate_image:image.path})
      }
      this.imagesVisible = false
    },
    handleChange(value) {
      Object.assign(this, {
        value,
        data: [],
        fetching: false,
      });
    },
  }
}
</script>
