<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>教务中心</a-breadcrumb-item>
      <a-breadcrumb-item>学员证书</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-body students">
      <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
        <!-- <a-tab-pane key="1">
          <span slot="tab">证书发放<a-badge :count="0" /></span>
          <zsff v-if="activeKey==='1'"/>
        </a-tab-pane> -->
        <a-tab-pane key="2">
          <span slot="tab">学员证书<a-badge :count="0" /></span>
          <xyzs v-if="activeKey==='2'"/>
        </a-tab-pane>
        <a-tab-pane key="3">
          <span slot="tab">发放记录<a-badge :count="0" /></span>
          <ffjl v-if="activeKey==='3'"/>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import zsff from './zsff/index'
import ffjl from './ffjl/index'
import xyzs from './xyzs/index'

export default {
  components: {
    zsff,
    ffjl,
    xyzs,
  },
  data() {
    return {
      activeKey: '2'
    }
  },

  methods: {
    changeTab(key) {
      this.activeKey = key
    }
  }
}
</script>

